*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  display: flex;
  background-color: #b59e7e;
  justify-content: space-between;
  align-items: center;
  padding: 1% 3%;
}

.logo {
  font-size: 1.6em;
  font-weight: 700;
  text-decoration: none;
  color: azure;
}

.navbar {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 1em;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 1.1em;
}

.nav-link:hover {
  color: #f3bd6b;
  font-size: 1.4em;
}

.App {
  background-image: url('https://cdnb.artstation.com/p/assets/images/images/015/232/885/large/andreia-polonia-dojo.jpg?1547584214');
  height: 100vh;
}

.home {
  text-align: center;
  padding: 3%;
}

.home-header {
  font-size: 3em;
  color: white;
}

.home-text {
  font-size: 2em;
  color: azure;
}

.calculator-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.math-text {
  font-size: 2.4em;
  font-weight: 700;
  color: azure;
}

.calculator {
  width: 30%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: 50px 50px 50px 50px 50px 50px;
}

.calculator-input {
  background-color: rgb(143, 153, 153);
  grid-column: 1/5;
  font-size: 2em;
  border-radius: 0.3em;
}

.calculator-button {
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  border-radius: 0.3em;
}

.calculator-button:nth-child(18) {
  grid-column: 1/3;
}

.calculator-button:nth-child(4n + 1) {
  background-color: orange;
}

.calculator-button:nth-child(20) {
  background-color: orange;
}

.quote {
  display: flex;
  justify-content: center;
  padding: 100px 10%;
}

.loading {
  font-size: 2em;
  color: azure;
}

.quote-text {
  background-color: #8b6743;
  color: azure;
  padding: 2%;
  font-size: 2em;
}

.author {
  font-weight: 700;
}

.error-message {
  font-size: 2em;
  color: azure;
}
